import API from '../../utils/API'

import {SET_USER_DATA, SHOW_MESSAGE} from './types'

export function setUserData(user) {
    return (dispatch) => {
        dispatch({
            type: SET_USER_DATA,
            data: user,
        });
    };
}

export const getAllUsers = ({token, user_type}) => async(dispatch) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            "jwt-token": ` ${token}`,
        },
        params: {
            user_type
        }
    };
    try {
        const res = await API.get("/users/all/", config);
        return res;

    } catch (error) {
        dispatch({
            type: SHOW_MESSAGE,
            payload: {
                title: `Failed`,
                message:
                    error?.response?.data?.message
                        ? error?.response?.data?.message
                        : ` failed`,
                severity: "error",
                statusCode: error?.response?.status
            },
        });
    }
};

export const getUserByID = ({token, userId}) => async (dispatch) => {
    const config = {
        headers: {
            "Content-Type" : "application/json",
            "jwt-token": `${token}`,
        },
    }
    try {
        const res = await API.get(`/users/${userId}`, config)
        return res;
    } catch (error) {
        dispatch({
            type: SHOW_MESSAGE,
            payload: {
                title: `Failed`,
                message:
                    error?.response?.data?.errors?.message
                        ? error?.response?.data?.errors?.message
                        : ` failed`,
                severity: "error",
                statusCode: error?.response?.status
            },
        });
    }
}

export const updateUser = ({token, data, userId}) => async (dispatch) =>{
    const config = {
        headers: {
            "Content-Type" : "application/json",
            "jwt-token": `${token}`,
        },
    }
    try {
        const res = await API.put(`/users/update/profile/${userId}`, data, config);
        dispatch({
            type: SHOW_MESSAGE,
            title: 'Update User',
            payload: {
                title: `Success`,
                message: 'User updated successfully',
                severity: "success",
            },
        });
        return 'success'

    } catch (error) {
        dispatch({
            type: SHOW_MESSAGE,
            payload: {
                title: `Failed`,
                message:
                    error?.response?.data?.errors?.message
                        ? error?.response?.data?.errors?.message
                        : ` failed`,
                severity: "error",
                statusCode: error?.response?.status
            },
        });
    }

}

export const deleteUser = ({token, userId}) => async (dispatch) => {
    const config = {
        headers: {
            "Content-Type" : "application/json",
            "jwt-token": `${token}`,
        },
    }
    try {
        const res = await API.delete(`/users/delete/${userId}`, config)
        dispatch({
            type: SHOW_MESSAGE,
            title: 'Delete User',
            payload: {
                title: `Success`,
                message: 'User deleted successfully',
                severity: "success",
            },
        });
        return 'success';
    } catch (error) {
        dispatch({
            type: SHOW_MESSAGE,
            payload: {
                title: `Failed`,
                message:
                    error?.response?.data?.errors?.message
                        ? error?.response?.data?.errors?.message
                        : ` failed`,
                severity: "error",
                statusCode: error?.response?.status
            },
        });
    }
}

export const addInstructor = ({token, data}) => async (dispatch) => {
    const config = {
        headers: {
            "Content-Type" : "application/json",
            "jwt-token": `${token}`,
        },
    }
    try {
        const res = await API.post(`/instructor/add`, data, config);
        dispatch({
            type: SHOW_MESSAGE,
            title: 'Add Instructor',
            payload: {
                title: `Success`,
                message: 'Instructor added successfully',
                severity: "success",
            },
        });
        return "success";

    } catch (error) {
        dispatch({
            type: SHOW_MESSAGE,
            payload: {
                title: `Failed`,
                message:
                    error?.response?.data?.errors?.error
                        ? error?.response?.data?.errors?.error
                        : ` failed`,
                severity: "error",
                statusCode: error?.response?.status
            },
        });
    }


}

export const changeStatus = ({token, data}) => async (dispatch) => {
    const config = {
        headers: {
            "Content-Type" : "application/json",
            "jwt-token": `${token}`,
        },
    }
    try {
       const res = await API.put(`/users/update/status`, data, config);
        dispatch({
            type: SHOW_MESSAGE,
            title: 'Update User Status',
            payload: {
                title: `Success`,
                message: 'User Status updated successfully',
                severity: "success",
            },
        });
        return 'success'

    } catch (error) {
        dispatch({
            type: SHOW_MESSAGE,
            payload: {
                title: `Failed`,
                message:
                    error?.response?.data?.errors?.message
                        ? error?.response?.data?.errors?.message
                        : ` failed`,
                severity: "error",
                statusCode: error?.response?.status
            },
        });
    }

}

export const bulkDeleteUsers = ( {token,data} ) => async (dispatch) => {
    const config = {
        headers: {
            "Content-Type" : "application/json",
            "jwt-token": `${token}`,
        },
    }
    const body = {
        id: data
    }
    try {
        const res = await API.post('/users/delete', body, config);
        dispatch({
            type: SHOW_MESSAGE,
            title: 'User deleted',
            payload: {
                title: `Success`,
                message: 'User deleted successfully',
                severity: "success",
            },
        });
        return 'success'

    } catch (error) {
        dispatch({
            type: SHOW_MESSAGE,
            payload: {
                title: `Failed`,
                message:
                    error?.response?.data?.errors?.message
                        ? error?.response?.data?.errors?.message
                        : ` failed`,
                severity: "error",
                statusCode: error?.response?.status
            },
        });
    }

}
