import { useState, useEffect } from "react";
import storeConfig from "./redux/store";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react"
import {BrowserRouter as Router, Route, Redirect, Routes, Navigate,useLocation} from 'react-router-dom';
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import Sidenav from "./layout/Sidenav/index";
import Configurator from "./layout/Configurator/index";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import routes from "routes";
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import SignIn from "./pages/auth/signIn";
import PrivateRoute from "components/Routing/PrivateRoute";
import {ConfirmProvider} from "material-ui-confirm";
import DashboardLayout from "./layout/DashboardLayout";
import DashboardNavbar from "./layout/DashboardNavbar";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const { store, persistor } = storeConfig;


export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });
  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <ConfirmProvider>
        <CssBaseline />
        {layout === "dashboard" && (
            <>
            {pathname !== "/login" &&
              <Sidenav
                  color={sidenavColor}
                  brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                  brandName="Scholars Admin"
                  routes={routes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
              />}
                <Configurator />
            </>
        )}

        {layout === "vr" && <Configurator />}

        <PersistGate persistor={persistor}>
          <Provider store={store}>
            {pathname == "/login" ? (
                  <Routes>
                    <Route path="/login" exact element={<SignIn />} />
                  </Routes>
            ) : (
                <DashboardLayout>
                  <DashboardNavbar/>
                  <Routes>
                    <Route  element={<PrivateRoute/>}>
                      {getRoutes(routes)}
                      <Route path="*" element={<Navigate to="/dashboard" />} />
                    </Route>
                  </Routes>
                </DashboardLayout>
            )}

          </Provider>
        </PersistGate>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            theme="dark"
        />

      </ConfirmProvider>
    </ThemeProvider>
  );
}
