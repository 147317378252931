import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux'
import {  Navigate } from 'react-router-dom'
import { loginWithEmailAndPassword }  from  '../../redux/actions/AuthActions'
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "./BasicLayout/index";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

function SignIn() {

  const loginState = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    const res = dispatch(loginWithEmailAndPassword({ ...data }));
  };
  if (loginState?.success) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" mx={2} mt={-3} p={2} mb={1} textAlign="center" >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}> Welcome to Scholars Dashboard </MDTypography>
        </MDBox>
        <form onSubmit={handleSubmit(onSubmit)}>  
          <MDBox pt={4} pb={3} px={3}>
            <MDBox>
              <MDBox mb={2}>
                <MDInput type="email" label="Email" fullWidth name="email" id="email" { ...register("email",
                {
                    required: "Email is Required!",
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Invalid email",
                    },
                })}
                helperText={errors.email && errors.email.message} error={!!errors.email} />
              </MDBox>
              <MDBox mb={2}>
                <MDInput type="password" label="Password" fullWidth name="password"  id="password" {...register("password",
                { required: "Password is Required",})}  helperText={errors.password && errors.password.message} error={!!errors.password} />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton type="submit" variant="gradient" color="info" fullWidth>
                  log in
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </form>
      </Card>
    </BasicLayout>
  );
}

export default SignIn;
