import axios from "axios";
import localStorageService from "./LocalStorageServices";

class JwtAuthService {

    loginWithEmailAndPassword = (data) => {
        this.setSession(data.token);
        this.setUser(data);
    };

    logout = () => {
        this.setSession(null);
        this.removeUser();
    };

    setSession = (token) => {
        if (token) {
            localStorage.setItem("jwt_token", token);
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        } else {
            localStorage.removeItem("jwt_token");
            delete axios.defaults.headers.common["Authorization"];
        }
    };

    setUser = (user) => {
        localStorageService.setItem("auth_user", user);
    };

    removeUser = () => {
        localStorage.removeItem("auth_user");
    };
}

export default new JwtAuthService();