import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {getAllUsers, deleteUser, updateUser, changeStatus, bulkDeleteUsers} from '../../../redux/actions/UserActions'
import MDBox from "../../MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "../../MDTypography";
import { DataGrid } from '@mui/x-data-grid';
import {Fab, Icon, IconButton, Stack, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEye, faPencil, faPlus, faTrash} from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import { Box } from '@mui/system';
import {useConfirm} from "material-ui-confirm";
import MDButton from "../../MDButton";
import AddIcon from '@mui/icons-material/Add';
import MDBadge from "../../MDBadge";
import {Delete, ToggleOff, ToggleOn} from "@mui/icons-material";
import {toast, ToastContainer} from "react-toastify";

const AllUsers = () => {

    let addButton;
    const confirm = useConfirm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const windowUrl = window.location.pathname;
    const user_type =  windowUrl.slice(1, -1);
    const [data, setData] = useState([]);
    const [userData, setUserData] =useState([]);
    const [show, setShow] = useState(false);
    const [isDataChange, setIsDataChange] = useState(true);
    const [selectedRows, setSelectedRows] = useState([]);

    if (user_type=="instructor") {
        addButton= <Fab variant="extended" aria-label="add"title="Add Instructor" style={{  float: 'right'}} sx={{mt: "-20px"}} onClick={() => {
            navigate(`/instructor/add`)
        }}>
            <AddIcon fontSize="small"/>
        </Fab>
    }

    const getBadge = (status) => {
        switch (status) {
            case 'active': case 'approved':
                return 'success'
            case 'deactive' : case 'declined':
                return 'error'
            default:
                return 'secondary'
        }
    }
    const handleDelete = (id) => {
        confirm({ description: 'You want to delete user' })
            .then( () => {
                const res =  dispatch(
                    deleteUser({
                        token: user.token,
                        userId: id,
                    })
                );
                setIsDataChange(true);
            }).catch(() => {
            console.log("error")
        });

    }
    const bulkDelete = () => {
        if (selectedRows.length != 0) {
            dispatch(bulkDeleteUsers({
                token: user.token,
                data: selectedRows
            }))
            setIsDataChange(true);
            // getStudents({ user_type: user_type });

        } else {
            toast("Nothing Selected");
        }
    }
    const bulkStatus = (status) => {
        if (selectedRows.length != 0) {
            dispatch( changeStatus({
                token:user.token,
                data: {id:selectedRows, status:status}
            }));
            setIsDataChange(true);
            // getStudents({ user_type: user_type });
        } else {
            toast("Nothing Selected")
        }

    }
    const columns = [
        { field: "_id", hide: true },
        { field: 'name', headerName: 'Name', flex:1, valueGetter: (params) =>
            `${params.row.first_name || ''} ${params.row.last_name || ''}`
        },
        { field: 'username', headerName: 'UserName',  flex:1 },
        { field: 'email', headerName: 'Email',  flex:1 },
        { field: 'user_status', headerName: 'User Status',  flex:1 , renderCell: (rowData) => (
                <MDBox ml={-1}>
                    <MDBadge badgeContent={rowData.row.user_status} color={getBadge(rowData.row.user_status)} variant="gradient" size="sm" />
                </MDBox>
        ),},
        { field: 'status', headerName: 'Status',  flex:1, renderCell: (rowData) => (
                <MDBox ml={-1}>
                    <MDBadge badgeContent={rowData.row.status} color={getBadge(rowData.row.status)} variant="gradient" size="sm" />
                </MDBox>
        ), },
        {
            field: "actions", headerName: "Action", sortable: false, filter:false, flex:1, renderCell: (props) => {
                return (
                    <Stack direction="row" >
                        <IconButton aria-label="detail">
                            <FontAwesomeIcon icon={faEye} title="Detail" size="xs" onClick={ () => {
                                setShow(true)
                                setUserData(props.row);
                            }}/>
                        </IconButton>
                        <IconButton aria-label="edit">
                            <FontAwesomeIcon icon={faPencil} title="Edit" size="xs" onClick={() => {
                                        navigate(`/user/edit/${props.row._id}`)
                                    }}/>
                        </IconButton>
                        <IconButton aria-label="delete">
                            <FontAwesomeIcon icon={faTrash} title="Delete" size="xs" onClick={() => handleDelete(props.row._id)}/>
                        </IconButton>
                    </Stack>
                );
            },
        },
      ];
      const getStudents = async ({ user_type }) => {
        const res = await dispatch(
            getAllUsers({
                token: user.token,
                user_type:user_type
            })
        );
        if(res){
            setData(res?.data?.data);
            setIsDataChange(false);
        }
    }
    useEffect(async ()=> {
            await getStudents({ user_type: user_type });
    },[user_type, isDataChange]);

    return(
        <>
            <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card>
                        <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                            <MDTypography variant="h6" color="white"> All {user_type.charAt(0).toUpperCase() + user_type.slice(1)}s </MDTypography>
                            {addButton}
                            <Box style={{  float: 'right'}} sx={{mt: "-20px", mr: "20px"}}>
                                <MDButton variant="contained" color="white" size="small" sx={{mr: "20px"}} onClick={bulkDelete}>
                                    <Delete/>&nbsp;Delete All
                                </MDButton>
                                <MDButton variant="contained" color="white" size="small" sx={{mr: "20px"}} onClick={() => bulkStatus("active")}>
                                   <ToggleOn/> &nbsp;Active All
                                </MDButton>
                                <MDButton variant="contained" color="white" size="small" sx={{mr: "20px"}} onClick={() => bulkStatus("deactive")}>
                                    <ToggleOff/> &nbsp;Deactive All
                                </MDButton>
                            </Box>

                        </MDBox>
                        <div style={{ height: 700 }}>
                            <DataGrid
                                rows={data}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[10]}
                                disableSelectionOnClick
                                getRowId={(r) => r._id}
                                disableColumnMenu
                                checkboxSelection
                                onSelectionModelChange={(ids) => {
                                    const selectedIDs = new Set(ids);
                                    const selectedRows = data.filter((row) =>
                                        selectedIDs.has(row._id),
                                    );
                                    setSelectedRows(ids);
                                }}
                            />
                        </div>
                    </Card>
                </Grid>
            </Grid>
        </MDBox>

        <Modal show={show} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>User Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table class="table table-striped table-bordered">
                    <tbody id="user-details-table">
                    <tr><th>Name:</th><td colspan="5">{userData.first_name} {userData.last_name}</td></tr>
                    <tr><th>User Name:</th><td colspan="5">{userData.username}</td></tr>
                    <tr><th>Email:</th><td colspan="5">{userData.email}</td></tr>
                    <tr><th>Phone No:</th><td colspan="5">{userData.phone_no}</td></tr>
                    <tr><th>User Type:</th><td colspan="5">{userData.user_type}</td></tr>
                    <tr><th>User Status:</th><td colspan="5">{userData.user_status}</td></tr>
                    <tr><th>Status:</th><td colspan="5">{userData.status}</td></tr>
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ () => {
                    setShow(false)
                    setUserData("");
                }}> Close</Button>
            </Modal.Footer>
        </Modal>
        </>
    )
}

export default AllUsers;