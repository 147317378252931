import Dashboard from "./components/Dashboard/dashboard";
import Users from  "./components/Dashboard/Users/allUsers"
import Icon from "@mui/material/Icon";
import AddEditUser from "pages/users/addEditUser";
import SignIn from "./pages/auth/signIn";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard11",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Students",
    key: "students",
    icon: <Icon fontSize="small">people_Alt</Icon>,
    route: "/students",
    component: <Users />,
  },
  
  {
    type: "collapse",
    name: "Instructors",
    key: "instructors",
    icon: <Icon fontSize="small">groups</Icon>,
    route: "/instructors",
    component: <Users />,
  },
  {
    key: "user_edit",
    route: "/user/edit/:id",
    component: <AddEditUser />,
  },
  {
    key: "add_instructor",
    route: "/instructor/add",
    component: <AddEditUser />,
  },

];

export default routes;
