import {
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGIN_LOADING
} from "../actions/types";

const initialState = {
    success: false,
    loading: false,
    error: "",
};

const AuthReducer = function (state = initialState, action) {
    switch (action.type) {
        case LOGIN_LOADING: {
            return {
                ...state,
                loading: true,
            };
        }
        case LOGIN_SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
            };
        }
        case LOGIN_ERROR: {
            return {
                success: false,
                loading: false,
                error: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default AuthReducer;
