import {
  SET_USER_DATA, SHOW_MESSAGE,
  USER_LOGGED_OUT
} from "../actions/types";
import {toast} from "react-toastify";

const initialState = {};

const userReducer = function (state = initialState, action) {
  // console.log(action)
  switch (action.type) {
    case SET_USER_DATA: {
      return {
        ...state,
        ...action.data,
      };
    }
    case USER_LOGGED_OUT: {
      return {};
    }
    case SHOW_MESSAGE: {
      if (action.payload.title == "Failed") {
        toast.error(action.payload.message);
      } else {
        toast.success(action.payload.message);
      }
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
