import axios from "axios";
// import jwtAuthService from "../services/jwtAuthService";
import { setUserData } from "../redux/actions/UserActions";
import Store from '../redux/store'

const instance = axios.create({
    baseURL: 'http://scholars-admin.crunchyapps.com:5000',
    // baseURL: 'http://localhost:5000',
    headers: {
        "Content-Type": "application/json",
    },
    responseType: "json",
});



instance.interceptors.request.use(
    (config) => {
        const user = JSON.parse(localStorage.getItem('auth_user'));
        if (user?.token) {
            config.headers["Authorization"] = 'Bearer ' + user.token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        // console.log(err.response);
        const originalConfig = err.config;
        const user = JSON.parse(localStorage.getItem('auth_user'));

        if (originalConfig.url !== "/auth/login" && err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;

                try {
                    // console.log(5)
                    //
                    // const rs = await instance.post("/api/v1/admin/authentication/refresh", {
                    //     token: user?.refreshToken,
                    // });

                    // const refreshToken = rs.data.refreshToken;
                    // const authToken = rs?.data?.token
                    // await Store?.store?.dispatch(setUserData({ ...rs?.data?.user, token: authToken, refreshToken }));
                    // jwtAuthService.loginWithEmailAndPassword({ ...rs?.data?.user, token: authToken, refreshToken });
                    // return instance(originalConfig);
                } catch (_error) {
                    // console.log(8);
                    // console.log(_error);
                    return Promise.reject(_error);
                }
            }
        }
        // console.log(err);
        return Promise.reject(err);
    }
);

export default instance;