import {LOGIN_LOADING, SHOW_MESSAGE, LOGIN_SUCCESS, LOGIN_ERROR} from './types'
import API from '../../utils/API'
import jwtAuthService from '../../services/JwtAuthService'
import {setUserData} from './UserActions'
import {Route} from "react-router-dom";
// import Dashboard from "../../components/Dashbaord/dashboard";
import React from "react";

export const loginWithEmailAndPassword = ({ email, password }) => async (
    dispatch
) => {
    dispatch({
        type: LOGIN_LOADING,
    });
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    const body = JSON.stringify({ email, password });
    try {
        const res = await API.post("/auth/login", body, config);
        const token = res.data.data.token
        // const refreshToken = res.data.data.refreshToken;
        jwtAuthService.loginWithEmailAndPassword({...res?.data?.data?.user, token});
        dispatch(setUserData({...res?.data?.data?.user, token}));
        dispatch({
            type: SHOW_MESSAGE,
            payload: {
                title: `Success`,
                message: 'Logged In Successfully',
                severity: "success",
            },
        });
        dispatch({
            type: LOGIN_SUCCESS,
        });
        if (res.data.status === 200) {
            // return <Route path="/dashboard" exact element={<Dashboard/>} />
        }
    } catch (error) {
        dispatch({
            type: LOGIN_ERROR,
            payload: error?.response?.data?.errors?.message,
        });
        dispatch({
            type: SHOW_MESSAGE,
            payload: {
                title: `Failed`,
                message:
                    error?.response?.data?.errors?.message
                        ? error?.response?.data?.errors?.message
                        : ` failed`,
                severity: "error",
                statusCode: '400'

            },
        });
    }
};