import React from "react";
import DashboardLayout from "../../layout/DashboardLayout/index";
import DashboardNavbar from "../../layout/DashboardNavbar/index";
import MDBox from "../MDBox";

const Dashboard = () => {
    return(
            <MDBox pt={6} pb={3}>
                Dashboard
            </MDBox>
    )

}

export default Dashboard;