
export const SET_USER_DATA = "SET_USER_DATA";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";


