import { Card, Grid, MenuItem, TextField, Select, InputLabel, FormControl, Button, Alert } from "@mui/material";
import { Box } from "@mui/system";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "layout/DashboardLayout";
import DashboardNavbar from "layout/DashboardNavbar";
import React, {useEffect, useState} from "react";
import {getUserByID, updateUser, addInstructor} from "../../redux/actions/UserActions";
import {useDispatch, useSelector} from "react-redux";
import {useParams, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";

const AddEditUser = () => {

    let heading;
    const params = useParams();
    const url = window.location.pathname.replace(/^\/([^\/]*).*$/, '$1');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const [disable, setDisable] = useState(true);
    const [hide, setHide] = useState( true);
    const [password, setPassword] = useState(false);
    const [values, setValues]= useState({status: 'active', user_status: 'approved'})
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const statusArray = [{ value: 'active', label: 'Active' }, { value: 'deactive', label: 'Deactive' }];
    const userStatusArray = [{value: 'approved', label: 'Approved'}, {value: 'declined', label: 'Declined'}];
    if (url =="instructor") {
        heading = "Add Instructor"
    } else {
        heading = `Update ${url}`
    }
    const changeHandler = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const getUser = async () => {
        const res = await dispatch(
            getUserByID({
                token: user.token,
                userId: params.id,
            })
        );
        if(res){
            var value = res?.data?.data;
            if (value._id != params.id) {
                setHide(false);
                setValue('headline', value.headline);
                setValue('biography', value.biography);
                setValue('website_link', value.website_link);
                setValue('youtube_link', value.youtube_link);
                setValue('twitter_link', value.twitter_link);
                setValue('linkedin_link', value.linkedin_link);
                setValue('facebook_link', value.facebook_link);
                value = value.user;
            }
            setValue('first_name', value.first_name);
            setValue('last_name', value.last_name);
            setValue('username', value.username);
            setValue('email', value.email);
            setValue('phone_no', value.phone_no);
            setValues({status: value.status, user_status: value.user_status})
        }
    };

    useEffect(()=> {
        if (url ==="instructor") {
            setDisable(false);
            setPassword(true);
            setHide(false);
        } else {
            getUser();
        }}, []
    );

    const onSubmit = async (data) => {
        if (params.id) {
            const res = await dispatch(updateUser({ token: user.token,  data: {...data, ...values}, userId: params.id }));
            if (res === 'success') navigate(-1)

        } else {
            const res = await dispatch(addInstructor({ token: user.token,  data: {...data, ...values} }));
            if (res === 'success') navigate(-1)

        }
    };

    return (
        <>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                                <MDTypography variant="h6" color="white"> {heading} </MDTypography>
                            </MDBox>
                            <div style={{ height: 'auto' }} >
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <MDBox mx={5} mt={4} py={3} px={2}>
                                        <Box sx={{ width: '100%' }}>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                <Grid item xs={6}>
                                                    <TextField type="text" id="first_name" label="First Name" fullWidth name="first_name"
                                                               {...register("first_name")}  InputLabelProps={{ shrink: params.id }} inputProps={{maxLength: 15}}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField type="text" id="last_name" label="Last Name" fullWidth name="last_name"
                                                               {...register("last_name")} InputLabelProps={{ shrink: params.id }} inputProps={{maxLength: 15}}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}  mt={2}>
                                                    <TextField type="text" id="username" label="User Name" fullWidth name="username" disabled={disable} inputProps={{maxLength: 20}}
                                                               {...register("username", {
                                                                   required: "Username is required"
                                                               })}
                                                               InputLabelProps={{ shrink: params.id }} helperText={errors.username && errors.username.message} error={!!errors.username}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} mt={2}>
                                                    <TextField type="email" id="email" label="Email" fullWidth name="email" disabled={disable} inputProps={{maxLength: 30}}
                                                               {...register("email",{
                                                                       required: "Email is Required!",
                                                                       pattern: {
                                                                           value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                                           message: "Invalid email",
                                                                       },
                                                                   }
                                                               )}
                                                               InputLabelProps={{ shrink: params.id }} helperText={errors.email && errors.email.message} error={!!errors.email}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} mt={2}>
                                                    <TextField type="number" id="phone_no" label="Phone no" fullWidth name="phone_no"
                                                               {...register("phone_no", {
                                                                   required: "Phone No is required"
                                                               })}
                                                               InputLabelProps={{ shrink: params.id }} helperText={errors.phone_no && errors.phone_no.message} error={!!errors.phone_no}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} mt={2}>
                                                    <TextField type="password" id="password" label="Password" fullWidth name="password" required={password}
                                                               {...register("password")} InputLabelProps={{ shrink: params.id }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} mt={2}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Account Status</InputLabel>
                                                        <Select id="status"  label="Account Status" onChange={changeHandler} className="custom-select" name="status" value={values?.status}>
                                                            {statusArray.map((option) => (
                                                                <MenuItem key={`required-${option.value}`} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6} mt={2}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">User Status</InputLabel>
                                                        <Select id="user_status"  label="User Status" onChange={changeHandler} className="custom-select" name="user_status" defaultValue={'approved'} value={values?.user_status}>
                                                            {userStatusArray.map((option) => (
                                                                <MenuItem key={`required-${option.value}`} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} mt={3} mb={2} hidden={hide}>
                                                    <MDButton variant="gradient" color="info" > Instructor Profile </MDButton>
                                                </Grid>

                                                <Grid item xs={12} mt={2}>
                                                    <TextField type="text" id="biography" label="Biography" fullWidth name="biography" hidden={hide}
                                                               {...register("biography")} InputLabelProps={{ shrink: params.id }} inputProps={{maxLength: 60}}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} mt={2}>
                                                    <TextField type="text" id="headline" label="Headline" fullWidth name="headline" hidden={hide}
                                                               {...register("headline")}  InputLabelProps={{ shrink: params.id }} inputProps={{maxLength: 30}}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} mt={2}>
                                                    <TextField type="text" id="website_link" label="Website Link" fullWidth name="website_link" hidden={hide} inputProps={{maxLength: 25}}
                                                               {...register("website_link")}  InputLabelProps={{ shrink: params.id }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} mt={2}>
                                                    <TextField type="text" id="youtube_link" label="Youtube Link" fullWidth name="youtube_link" hidden={hide} inputProps={{maxLength: 25}}
                                                               {...register("youtube_link")} InputLabelProps={{ shrink: params.id }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} mt={2}>
                                                    <TextField type="text" id="twitter_link" label="Twitter Link" fullWidth name="twitter_link" hidden={hide} inputProps={{maxLength: 25}}
                                                               {...register("twitter_link")} InputLabelProps={{ shrink: params.id }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} mt={2}>
                                                    <TextField type="text" id="linkedin_link" label="LinkedIn Link" fullWidth name="linkedin_link" hidden={hide} inputProps={{maxLength: 25}}
                                                               {...register("linkedin_link")}  InputLabelProps={{ shrink: params.id }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} mt={2}>
                                                    <TextField type="text" id="youtube_link" label="Youtube Link" fullWidth name="youtube_link" hidden={hide} inputProps={{maxLength: 25}}
                                                               {...register("youtube_link")} InputLabelProps={{ shrink: params.id }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} mt={2} sx={{ mx: "10rem" }}container justifyContent="center">
                                                    <MDButton type="submit" variant="gradient" color="info" fullWidth> {heading} </MDButton>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </MDBox>
                                </form>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </>
    )
}

export default AddEditUser